import Button from "../../components/controls/Button";
import {
    listLlmDataSets,
    postTestSet,
    putTestSet,
    getTestSet,
    executeTest,
    listResults,
    getTest,
    deleteTestSet,
    executeCopilot
} from "../../services/dataSetService";
import {Select} from "../../components/controls/Select";
import React, {useContext, useEffect, useState} from "react";
import SetRow from "../../components/LlmSetsTesting/SetRow";
import ControlPoint from "@mui/icons-material/ControlPoint";
import Autopilot from "../../components/LlmSetsTesting/Autopilot";
import AddIcon from '@mui/icons-material/Add';
import ProjectContext from "../../context/project-context";
import CreationPopUp from "./CreationPopUp";
import PlayArrow from "@mui/icons-material/PlayArrow";
import DeleteIcon from '@mui/icons-material/Delete';
import WaitingPage from "./WaitingPage";
import {Input} from "@mui/material";

const defaultPrompt = "Porovnej tyto dva výsledky promptu maximálně v jedné větě.\n" +
    " \n" +
    "Napiš \"OK:\", pokud jsou výstupy podobné nebo mají stejný konverzační význam, co se týče sémantiky. Hint: fakta nebo věci, o kterých se mluví, se nemusí zcela shodovat. Jde o to, jestli plní stejnou konverzační funkci.\n" +
    " \n" +
    "Napiš \"NOK:\", pokud se významně liší a v jedné je něco výrazně navíc nebo v druhé něco jasně chybí.\n" +
    "Za OK nebo NOK vždy napiš srovnání a důvody."


export default function LlmTestingTool() {
    const {selectedProject} = useContext(ProjectContext);
    const [dataSets, setDataSets] = useState([])
    const [dataSet, setDataSet] = useState({})
    const [setRows, setSetRows] = useState([])
    const [creation, setCreation] = useState(false)
    const [edition, setEdition] = useState(false)
    const [testExe, setTestExe] = useState(false)
    const [testResults, setTestResults] = useState([])
    const [lastRes, setLastRes] = useState([])
    const [editedPrompt, setEditedPrompt] = useState("")
    const [testResultString, setTestResultString] = useState("")
    const [lastTestResultString, setLastTestResultString] = useState("")
    const [copilotRes, setCopilotRes] = useState([])
    const [prompt, setPrompt] = useState(defaultPrompt)
    const [deleting, setDeleting] = useState(false)
    const [model, setModel] = useState("gpt-4o")
    useEffect(() => {
        setSetRows([])
        setTestResults([])
        setCopilotRes([])
        handleGetTestSet()
        setEditedPrompt(getPromptId())
        // eslint-disable-next-line
    }, [dataSet])


    const handleGetTest = (itemId) => {
        if (!selectedProject?.id || !itemId) return
        listResults(selectedProject?.id, itemId).then(response => {
            const resId = response.data[response.data.length - 1]
            getTest(selectedProject?.id, resId).then(response => {
                setLastRes(response.data)
                setLastTestResultString(resId)
            })
        })
    }

    const handleTest = () => {
        if (!selectedProject?.id || !dataSet?.value) return
        setTestExe(true)
        const lastRes = JSON.parse(JSON.stringify(testResults))
        const lastResStr = testResultString
        executeTest(selectedProject.id, dataSet.value).then(response => {
            setTestExe(false)
            setCopilotRes([])
            setTestResults(response.data.items)
            setTestResultString(response.data["testResultString"])
            if (lastRes.length > 0) {
                setLastRes(lastRes)
                setLastTestResultString(lastResStr)
            }
        }).catch(error => {
            setTestExe(false)
            console.log(error)
        })
    }

    const handleRowChange = (r) => {
        const rw = r.map((row) => row.requestText)
        putTestSet(selectedProject.id, dataSet.value, dataSet.label, getPromptId(), rw).then((res) => {
            handleGetTestSet()
        }).catch(error => {
            console.log(error)
        })
    }

    const handleRows = () => {
        const r = setRows.map((row) => row.requestText)
        const rows = [...r, ""]
        putTestSet(selectedProject.id, dataSet.value, dataSet.label, getPromptId(), rows).then((res) => {
            handleGetTestSet()
        }).catch(error => {
            console.log(error)
        })
    }

    const handleGetTestSet = () => {
        if (!selectedProject?.id) return
        getTestSet(selectedProject.id, dataSet.value).then((res) => {
            setSetRows(res.data.items)
            handleGetTest(res.data.items[0]?.itemId)
        }).catch(error => {
            console.error(error)
        })
    }

    const getPromptId = () => {
        if (!selectedProject?.id) return
        let arr = dataSets.filter(d => d.id === dataSet.value)
        if (arr.length > 0) return arr[0]?.promptId
    }
    const updateTestingSet = (name, promptId) => {
        const r = setRows.map((row) => row.requestText)
        putTestSet(selectedProject.id, dataSet.value, name, promptId, r).then((response) => {
            listTestSets(dataSet.value)
        }).catch((error) => {
            console.log(error)
        })
    }
    const handleDelete = () => {
        setDeleting(true)
        setTimeout(() => {
            setDeleting(false)
        }, 1500)
        deleteTestSet(selectedProject?.id, dataSet?.value).then(() => {
            listTestSets()
        }).catch((e) => {
            console.log(e)
        })
    }
    const createTestingSet = (name, promptId) => {
        if (!selectedProject?.id) return
        postTestSet(selectedProject.id, name, promptId).then(response => {
            listTestSets()
        }).catch(error => console.log(error))
    }

    const listTestSets = (id = null) => {
        listLlmDataSets(selectedProject?.id).then(response => {
            setDataSets(response.data)
            if (dataSets.length === 0) return
            setDataSet({value: response.data[0]?.id, name: response.data[0]?.name})
        })
    }

    useEffect(() => {
        if (!selectedProject) return
        listTestSets()
        // eslint-disable-next-line
    }, [selectedProject]);

    const handleCopilot = () => {
        setTestExe(true)
        executeCopilot(selectedProject?.id, testResultString, lastTestResultString, prompt, model).then(response => {
            setCopilotRes(response.data)
            console.log(copilotRes)
            setTestExe(false)
        }).catch((e) => {
            console.log(e)
            setTestExe(false)
        })
    }

    return (
        <>
            {deleting ? <WaitingPage string={"Deleting..."}/> : null}
            {testExe ? <WaitingPage string={"Running..."}/> : null}
            {creation ? <CreationPopUp save={(name, promptId) => {
                createTestingSet(name, promptId)
            }} close={() => {
                setCreation(false)
            }}/> : null}
            {edition ? <CreationPopUp
                testName={dataSet.label}
                prompt={getPromptId()}
                save={(name, promptId) => {
                    updateTestingSet(name, promptId)
                }} close={() => {
                setEdition(false)
            }}/> : null}
            <div className="flex flex-col h-screen pr-6 text-left w-full">
                <div className='flex items-end justify-between w-full border-b pb-3 mb-3 text-sm'>
                    <div className='flex gap-3 mt-4'>
                        <label>
                            <span className='mb-2 block font-semibold'>Add set</span>
                            <Button variant="primary" icon={AddIcon} onClick={() => {
                                setCreation(true)
                            }}></Button>
                        </label>
                        <label>
                            <span className='mb-2 block font-semibold'>Test Set</span>
                            <Select options={dataSets.map((set) => {
                                return {
                                    value: set.id,
                                    label: set.name,
                                }
                            })} onChange={(e) => {
                                setDataSet(e)
                            }} value={dataSet?.value}/>
                        </label>
                        <label>
                            <span className='mb-2 block font-semibold'>Prompt ID</span>
                            <Input value={editedPrompt} variant={"secondary"} onChange={(e) => {
                                setEditedPrompt(e.target.value)
                            }}
                                   onBlur={(e) => {
                                       if (e.target.value.length < 1 || editedPrompt === e.target.value) return
                                       updateTestingSet(dataSet?.label ? dataSet.label : dataSet.value, editedPrompt)
                                   }}></Input>
                        </label>
                        <label>
                            <span className='mb-4 block font-semibold'></span>
                            <Button variant="primary" icon={PlayArrow} onClick={() => {
                                handleTest()
                            }}>
                                Run test
                            </Button>
                        </label>
                    </div>
                    <div className='flex gap-3'>
                        <Button variant="secondary" icon={DeleteIcon} onClick={() => {
                            handleDelete()
                        }}>
                            Delete test set
                        </Button>
                    </div>
                </div>
                <div className='flex gap-3'>
                    <div className='p-3 bg-[#f9f9f97c] w-10/12  rounded-lg'>
                        <div className='grid grid-cols-12 gap-3'>
                            <>
                                <div className='grid grid-col-1 col-span-2'>
                                    <h2 className='text-sm font-semibold mb-0'>User input</h2>
                                </div>
                                <div className='grid grid-col-1 col-span-4'>
                                    <h2 className='text-sm font-semibold mb-0'>Answer based on current prompt</h2>
                                </div>
                                <div className='grid grid-col-1 col-span-4'>
                                    <h2 className='text-sm font-semibold mb-0'>Answer based on previous prompt</h2>
                                </div>
                                <div className='grid grid-col-1 col-span-2'>
                                    <h2 className='text-sm font-semibold mb-0'>LLM comparison</h2>
                                </div>
                            </>
                            {setRows.length > 0 ? setRows.map((row, index) => (
                                <SetRow lastRes={lastRes[index]} id={row.itemId} currRes={testResults[index]}
                                        value={row["requestText"]}
                                        key={index}
                                        identifier={`textarea${row["requestText"]}`}
                                        copilotRes={copilotRes[index]}
                                        setValue={(val) => {
                                            let r = JSON.parse(JSON.stringify(setRows))
                                            r[index] = {
                                                requestText: val,
                                                itemId: row.itemId

                                            }
                                            handleRowChange(r)
                                        }}/>
                            )) : null}
                        </div>
                        <hr className='opacity-10'/>
                        <Button variant="outlined" icon={ControlPoint} className='mt-3 text-sm' onClick={() => {
                            handleRows()
                        }}>
                            Add user input
                        </Button>

                    </div>
                    <Autopilot runCopilot={() => {
                        handleCopilot()
                    }}
                               model={model}
                               setModel={(val) => {
                                   setModel(val)
                               }}
                               prompt={prompt}
                               setPrompt={setPrompt}/>
                </div>
            </div>
        </>
    )
}